const fitvids = require("../../../node_modules/fitvids/index.js");

(function ($) {
    fitvids();
    var fixProductButtons = function(){
        $("#products div.thin-gutters img").each(
            function(){
                var theWidth = $(this).width();
                var theHeight = $(this).height();
                var img = $(this);
                var productTitleWrapper = $(img).next('.title-wrapper');
                $(productTitleWrapper).attr('style', 'z-index: 10; margin-top: ' + (0 - (Math.round(theHeight/2))) + 'px; height: ' + Math.round(theHeight/2) + 'px;');
            }
        );
    };

    $(window).resize(function(){
        fixProductButtons();
    });

    $(window).on('load', function(){
        fixProductButtons();
    });

    // Handle the scroll to top button
    $(window).scroll(function() {
        var height = $(window).scrollTop();
        if(height  > 600) {
            // do something
            $("#to-top").show();
        } else {
            $("#to-top").hide();
        }
    });

    // The fix for bootstrap nested nav tabs.
    $('.nav-tabs').on('shown.bs.tab', 'a', function(e) {
        if (e.relatedTarget) {
            $(e.relatedTarget).removeClass('active');
        }
    });

    //scroll to anchor
    $(".scroll-link").on("click", function(){
        $('html, body').animate({
            scrollTop: $("#content").offset().top
        }, 1000);
    });
    $("#link-mobile").on("click", function(){
        $('html, body').animate({
            scrollTop: $("#content").offset().top
        }, 1000);
    });
    // scroll to top
    $("#to-top").on("click", function(){
        $('html, body').animate({
            scrollTop: $("body").offset().top
        }, 1000);
    });
})(jQuery);
